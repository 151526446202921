import React, { useRef } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import PolicyBox from '../middle/PolicyBox';

const useStyles = makeStyles({
  section: {
    margin: `calc(1rem + 2vw) auto`,
  },
});

const PoliciesBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <PolicyBox
            number="01."
            title="日本大改革／新所得倍増計画"
            contents={[
              {
                contentName: `社会保障改革「ベーシックインカム（BI）」導入`,
              },
              {
                contentName: `消費税減税（２年間５％）、所得税／法人税減税`,
              },
              {
                contentName: `地方分権（道州制）導入により２重行政解消`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyBox
            number="02."
            title="防災都市"
            contents={[
              {
                contentName: `地方区議会議員時代の防災委員の実績を基に安全対策強化`,
              },
              {
                contentName: `中高層マンションの共同防災（地震時のエレベータ復旧）確立`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyBox
            number="03."
            title="教育の無償化／多様性の街"
            contents={[
              {
                contentName: `全ての教育（含大学・大学院）無償化`,
              },
              {
                contentName: `リカレント（生涯）教育／リスキリング補助`,
              },
              {
                contentName: `選択的夫婦別姓制度の導入の促進`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyBox
            number="04."
            title="外交・防衛"
            contents={[
              {
                contentName: `台湾有事を想定したシーレーン防衛強化`,
              },
              {
                contentName: `対北朝鮮ミサイル防衛の強化／北朝鮮の核開発廃棄`,
              },
              {
                contentName: `国連に代わる新たな国際秩序の創設。`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyBox
            number="05."
            title="エネルギー政策"
            contents={[
              {
                contentName: `大型原子力発電所からSMR・核融合炉への転換`,
              },
              {
                contentName: `再生可能エネルギーと蓄積をセットで推進`,
              },
              {
                contentName: `新エネルギー（水温差発電、潮流発電）開発`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyBox
            number="06."
            title="憲法改正"
            contents={[
              {
                contentName: `「教育の無償化」「統治機構改革」「憲法裁判所設置」`,
              },
              {
                contentName: `平和主義、戦争放棄を堅持しつつ、自衛のための実力組織として自衛隊を明記`,
              },
            ]}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default PoliciesBox;
