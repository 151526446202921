import React from 'react';
import { PageProps } from 'gatsby';

import Container from '@material-ui/core/Container';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/Policies/section/FirstView';
import PoliciesBox from '@/components/Policies/section/PoliciesBox';
import StaticSEO from '@/components/common/section/StaticSEO';

const Policies: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      OgImageData="FirstViewSM"
      titleData="藤川たかし 公式HP | 6つの政策"
    />
    <FirstView />
    <Container maxWidth="md">
      <PoliciesBox />
    </Container>
  </Layout>
);

export default Policies;
